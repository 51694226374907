﻿.testimonial {
    margin-bottom: 1.25rem;

    .header {
        border-radius: 8px 8px 0 0;
        background-color: #455660;
        color: #fff;
        font-size: 1.25rem;
        font-weight: bold;
        padding-left: 0.75rem;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
    }

    .quote {
        background-color: #d8e7f0;
        padding: 1rem 1rem 1rem 1rem;
        font-weight: bold;
        font-size: 1rem;
        border-top: 1px solid #fff;
    }

    .signature {
        border-radius: 0 0 8px 8px;
        border-bottom: solid 1px #fff;
        background-color: #d8e7f0;
        text-align: right;
        padding-right: 3rem;
        padding-bottom:0.5rem;
        vertical-align: top;
        padding-top: 15px;
        color: #455660;
        margin: 0;
        font-size: 0.875rem;
        box-shadow: 0 8px 6px -6px #ccc;
    }
}
