﻿.classlist {
    border-bottom: solid 1px #ccc;
    width: 100%;
    border-collapse: collapse;
    background-color: #efefef;
    @extend .mb-3;

    th {
        background-color: #000;
        color: #fff;
        font-weight: bold;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
    }

    td {
        vertical-align: middle;
        border-bottom: solid 2px #fff;
        font-weight: bold;

        @include media-breakpoint-down(md) {
            font-size: 0.875rem;
        }
    }

    .name {
        padding-left: 0.5rem;
    }

    td.name {
    }

    .price {
        text-align: center;
        width: 90px;
    }

    .button {
        text-align: center;
        width: 125px;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
    }
}

.pricetablefull .pricetable {
    width: 100%;
    margin-bottom: 1.5rem;
}

/* Why 2? Good question.*/
.pricetable {
    margin: 0 auto 1rem auto;

    td, th {
        padding: 0.25rem 0.5rem;
        border: solid 1px #fff;
    }

    th {
        background-color: #6495ED;
        color: white;
        font-weight: bold;
    }

    td {
        background-color: #eeeeee;
    }

    .register {
        width: 105px;
        padding: 4px 0;
        text-align: center;
    }

    .price {
        text-align: center;
        width: 90px;
    }

    .name {
    }
}


.pricetable2 {
    border-collapse: collapse;
    margin: 1em auto;

    TD {
        padding: 4px 6px;
        background: #eee
    }

    .pricetitle TD {
        font-size: 0.875rem;
        background-color: #6495ED;
        color: white;
        font-weight: bold;
    }

    .pricedata TD {
        font-size: 0.875rem;
        background-color: #eeeeee;
        border: solid 1px #fff;
    }

    .pricedata .c1 {
        font-weight: bold;
    }

    .pricedata .c3 {
        width: 150px;
        text-align: center;
    }

    .pricedata .c2 {
        width: 80px;
        text-align: center;
    }
}

.pricetable2stretch {
    .pricetable2{
        width:100%;
        margin-left:0;
        margin-right:0;
    }
}

.form-radio {
    input[type="radio"] + label {
        font-weight: normal;
        margin-left: 6px;
        display: inline;
    }
}


.cartbox {
    font-size: 11px;
    width: 100%;
    background-color: #fff;

    td {
        padding: 2px 3px;

        &.price {
            text-align: right;
        }
    }

    .total td {
        border-top: 1px solid #ccc;
        font-weight: bold;
    }
}

#referfields {
    display: none;
}

#referopen {
    text-decoration: underline;
    cursor: pointer;
}

#referopenarea {
    margin-top: 1em;
    margin-bottom: 1em;
}

.referpopulated {
    #referfields {
        display: block;
    }

    #referopenarea {
        display: none;
    }
}

.validation-summary {
    @extend .alert;
    @extend .alert-danger;
}


/* Cart */
.regsum {
    width: 100%;
    border-collapse: collapse;

    .regsumdesc {
        padding-top: 2px;
        padding-bottom: 2px;
        padding-right: 8px;
        width: 75%;
    }

    .regsumprice {
        padding-top: 2px;
        padding-bottom: 2px;
        padding-left: 8px;
        text-align: right;
        width: 25%;
    }

    .regsumdescfoot {
        padding-top: 2px;
        padding-bottom: 2px;
        padding-right: 8px;
        border-top: solid 1px black;
        font-weight: bold;
    }

    .regsumpricefoot {
        padding-top: 2px;
        padding-bottom: 2px;
        padding-left: 8px;
        text-align: right;
        border-top: solid 1px black;
        font-weight: bold;
    }
}
