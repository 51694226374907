﻿.page-home {

    #socialbuttons {
        margin: 1em auto;
    }

    #googleplusone {
        vertical-align: top;
        padding-right: 0.5rem;
    }

    #facebooklike {
        vertical-align: top;
        padding-left: 0.5rem;
    }

    .home-right {
        color: #fff;
        padding-top: 1rem;
        @include font-family-arimo;
        font-size: 0.875rem;

        @include media-breakpoint-only(md) {
            padding-top: 2rem;
            font-size: 1rem;
        }

        @include media-breakpoint-only(lg) {
            padding-top: 2rem;
            font-size: 1rem;
        }

        @include media-breakpoint-only(xl) {
            padding-top: 1.25rem;
            font-size: 1.25rem;
        }

        @include media-breakpoint-only(xxl) {
            padding-top: 2.25rem;
            font-size: 1.25rem;
        }

        @include media-breakpoint-only(xxl) {
            padding-top: 2.25rem;
            font-size: 1.375rem;
        }
    }

    .cycle-slideshow {
        margin-top: 0.625rem;
        margin-bottom: 0.625rem;

        img {
            width: 100%;
            height: auto;
            display: none;
        }
    }

    .home-icon h3 {
        font-size: 1rem;
        background: url('/img/home-icons/Home.png') no-repeat top left;
        padding-left: 60px;
        min-height: 48px;
        @include font-family-arimo;

        &.home-icon-home {
            background-image: url('/img/home-icons/Home.png');
        }

        &.home-icon-success {
            background-image: url('/img/home-icons/Success.png');
        }

        &.home-icon-certificate {
            background-image: url('/img/home-icons/Certificate.png');
        }

        &.home-icon-tag {
            background-image: url('/img/home-icons/Tag.png');
        }

        &.home-icon-learn {
            background-image: url('/img/home-icons/Learn.png');
        }

        &.home-icon-stat {
            background-image: url('/img/home-icons/Stat.png');
        }
    }
}
