﻿.mainnav {
    .navbar-brand {
        img {
            max-height: 40px;
        }

        @include media-breakpoint-up(lg) {
            display:none;
        }
    }

    --bs-navbar-color: #1e5dd1;
    --bs-navbar-hover-color: #6a9cf7;

    #menu-register a {
        color: #8a0c06;
        @extend .fw-bold;
    }
}
