﻿

/* styles for validation helpers */
.field-validation-error {
    color: #b94a48;
}

.field-validation-valid {
    display: none;
}

input.input-validation-error {
    border: 1px solid #b94a48;
}

input[type="checkbox"].input-validation-error {
    border: 0 none;
}

.validation-summary-errors {
    color: #b94a48;
}

.validation-summary-valid {
    display: none;
}


.page-header {
    @extend .pb-1;
    @extend .border-bottom;
    @extend .mb-3;
}

.feature-single,
.feature,
.footer,
a.readmore,
ul.filter-widget a.current,
span.pagination-number:hover,
div.pagination span.active,
ul.filter-page a.current,
ul.four-column li a.port-readmore:hover,
ul.three-column li a.port-readmore:hover,
ul.two-column li a.port-readmore:hover,
ul.one-column li a.port-readmore:hover,
.home-content table.pricing tr th,
.home-content table.pricing {
    background: #1e5dd1;
    background: linear-gradient(to bottom, #1e5dd1 0%,#53a4e2 100%);
    color: #fff;
}

.feature-single{
    @extend .py-3;
}

.gray-gradient {
    background: #d9d9d9;
    background: linear-gradient(to bottom, #d9d9d9 0%, #e9e9e9 10px, #e9e9e9 100%);
}


.internalContainer {
    height: 5px;
    color: #ffffff;
    overflow: hidden;
    margin-left: $half-gutter;
    margin-right: $half-gutter;
    background: #0682c2;
}

.footer-sub {
    background: #E9E9E9;

    .copyright {
        color: #666666;
        font-size: 0.625rem;
    }
}

.footer {
    padding-top: 0.75rem;

    h4 {
        padding: 0;
        font-weight: bold;
        font-size: 1rem;
        color: #e9e9e9;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            color: #FFF;
            border-top: 1px solid #4878d2;
            padding: 0.5rem 0.25rem 0.5rem 0px;
            margin: 0;
            color: #949494;
            list-style: none;
            font-size: 0.8125rem;
        }
    }

    a {
        color: #FFF;
        text-decoration:none;

        &:hover {
            color: #9fbaeb;
        }
    }
}


.widget {
    margin: 1rem 0;

    &:first-child {
        margin-top: 1rem;
    }


    h1, h3 {
        font-size: 1.25rem;
        padding: 0 0 0.25rem 0;
        margin: 0 0 0.5rem 0;
        text-align: center;
        border-bottom: dotted 1px #31708f;
    }


    &.box {
        color: #245269;
        background-color: #d9edf7;
        border-radius: 4px;
        border: solid 1px #bce8f1;
        padding: 0.25rem;
    }

    &.button {
        text-align: center;

        img {
            display: block;
            max-width: 100%;
            height: auto;
            margin-left: auto;
            margin-right: auto;
        }
    }

    &.img {
        border: none;
        padding: 0;
        background-color: #fff;

        img {
            display: block;
            max-width: 100%;
            height: auto;
            margin-left: auto;
            margin-right: auto;
        }
    }

    ul.category-list {
        li {
            font-size:0.875rem;
        }
        margin-bottom:0;
    }
}

.securebox {
    font-size: 13px;

    .logo {
        text-align: center;
        margin-top: 8px;
    }

    .logo iframe {
        margin: 0 auto;
    }

    .logo img {
        margin-left: auto;
        margin-right: auto;
    }
}

.siteseal {
    width: 132px;
    height: 32px;
    overflow: hidden;
    border: none;
    margin-left: auto;
    margin-right: auto;
}


.faqitem {
    border-bottom: solid 1px #ccc;
    margin: 1.5rem auto;
    color: #4c4c4c;

    .question {
        font-weight: bold;
        margin-bottom: 0.5rem;
    }
}


.btn-register.disabled:hover,
.btn-register.disabled:focus,
.btn-register.disabled:active,
.btn-register.disabled.active,
.btn-register[disabled]:hover,
.btn-register[disabled]:focus,
.btn-register[disabled]:active,
.btn-register[disabled].active,
fieldset[disabled] .btn-register:hover,
fieldset[disabled] .btn-register:focus,
fieldset[disabled] .btn-register:active,
fieldset[disabled] .btn-register.active {
    background-color: #c70e23;
    border-color: #c70e23;
}

footer {
    font-size: 0.875rem;
    @extend .py-3;
    @extend .mt-3;
    border-top: solid 1px #ccc;

    a {
        @extend .link-secondary;
    }
}
