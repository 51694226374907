﻿.member {

    main {
        background-color: #fff;
    }
    /* school summary */
    .schoolsummary {
        width: 100%;
        font-size: 1rem;

        $border-color: #254689;
        $bkg-color: #efefef;

        td, th {
            border-spacing: 0;
            background-color: $border-color;
            border-width: 0;
            padding-top:0.25rem;
            padding-bottom:0.25rem;
        }

        a {
            color: #000;

            &:hover {
                color: Red;
            }
        }

        th {
            padding: 0.25rem;
            color: #fff;
            font-weight: bold;

            &:first-child {
                border-top-left-radius: 6px;
            }

            &:last-child {
                border-top-right-radius: 6px;
            }
        }

        tr {

            td {
                background-color: $bkg-color;
                text-align: left;

                &:first-child {
                    border-left: solid 2px $bkg-color;
                }

                &:last-child {
                    border-right: solid 2px $bkg-color;
                }
            }

            &.alt td {
                background-color: #fff;
            }

            &:last-child {
                td {
                    border-bottom: solid 2px $bkg-color;
                }
            }
        }


        .number {
            text-align: center;
            width: 5rem;
        }

        .title {
            text-align: left;
        }

        .status {
            text-align: center;
            width: 7.5rem;
        }

        .new {
            color: #666;
        }

        .inprogress {
            font-weight: bold;
        }
    }
    /* members */
    .memberlocation {
        background-color: #808080;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
        border-bottom: solid 4px #717171;
        color: #fff;
        font-weight: bold;

        .page {
            padding-right: 0.25rem;
            text-align: right;
        }

        .chapter {
            padding-left: 0.25rem;
        }
    }

    .membertime {
        border-bottom: solid 4px #a6a6a6;
        background-color: #eaeaea;

        .requiredchapter {
            padding-left: 0.25rem;
            padding-top: 0.25rem;
            padding-bottom: 0.25rem;
        }

        .requiredpage {
            padding-right: 0.25rem;
            padding-top: 0.25rem;
            padding-bottom: 0.25rem;
            text-align: right;
        }
    }


    .timebox {
        color: #fff;
        font-family: Courier, monospace;
        font-weight: bold;
        background-color: #929393;
        padding: 0.25rem;
        display: inline-block;
    }

    .lessonarea {
        margin-top: 5px;
    }

    h2.lessontitle {
        margin-top: 0;
        border-bottom: solid 1px #ccc;
        padding-bottom: 5px;
        margin-bottom: 10px;
    }

    .memberfootertimer {
        margin-bottom: 5px;
        border-bottom: solid 2px #a6a6a6;
        border-top: solid 2px #a6a6a6;
        background-color: #eaeaea;
        padding: 4px;
        text-align: center;
    }

    .memberfooter {
    }
    /* school outline */
    .OutlineBlock {
        background-color: #efefef;
        border: solid 3px #808000;
        border-radius: 6px;
        font-size: 14px;
        font-weight: normal;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        padding: 12px;

        .OutlineChapter {
            margin-left: 8px;
            clear: left;
            font-weight: bold;
            margin-top: 6px;
            margin-bottom: 6px;
        }

        .OutlineLesson {
            margin-left: 24px;
            clear: left;

            a {
                color: #000;
            }
        }

        .OutlineMedia {
            display: none;
        }

        .OutlineLessonNumber {
            color: #666;
            width: 30px;
            float: left;
        }
    }
    /* quiz & exams */

    .examanswers label {
        display: inline;
    }

    @include media-breakpoint-down(sm) {
        .membertime .requiredpage {
            padding-left: 0.25rem;
            text-align: left;
        }

        .memberlocation {
            margin-left: -1rem;
            margin-right: -1rem;
            margin-top: 0.625rem;
        }

        .membertime, .memberfootertimer {
            margin-left: -1rem;
            margin-right: -1rem;
        }
    }

    @include media-breakpoint-only(md) {
        .membertime .requiredpage {
            padding-left: 0.25rem;
            text-align: left;
        }
    }

    .exam-questionblock {
        margin-bottom: 1.5rem;


        .exam-question {
            font-weight: bold;
            margin-bottom: 0.5rem;
        }

        .exam-answers {
            margin-left: 2.5rem;

            .exam-answer {
                label {
                    input {
                        margin-right: 6px;
                    }
                }
            }
        }

        .exam-question-number {
            width: 2rem;
            display: inline-block;
        }

        &.field-validation-valid {
            display: block;
        }
    }


    .field-validation-error {
        border: 2px solid #ebccd1;
        border-radius: 6px;
        background-color: #f2dede;
        padding: 0.25rem;

        .validation-message {
            display: block;
        }
    }

    .validation-message {
        display: none;
        font-weight: bold;
        color: darkred;
    }
}
